export const data = [
    { date: 'Aug 20', health: 170 },
    { date: 'Aug 21', health: 140 },
    { date: 'Aug 22', health: 145 },
    { date: 'Aug 23', health: 150 },
    { date: 'Aug 24', health: 155 },
    { date: 'Aug 25', health: 155 },
    { date: 'Aug 26', health: 185 },
    { date: 'Aug 27', health: 120 },
    { date: 'Aug 28', health: 130 },
    { date: 'Aug 29', health: 110 },
    { date: 'Aug 30', health: 105 },
];
